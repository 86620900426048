<!-- 课程中心-订单管理-报名缴费 -->
<template>
  <div class="flex-col page">
    <div class="flex-col space-y-20 group_3">
      <div class="flex-row justify-between group_4 view">
        <div class="flex-row group_5">
          <span class="text_2">当前位置：订单管理</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929119465621.png"
            class="image_6" />
          <span class="text_3">报名缴费</span>
        </div>
        <div class="flex-row group_6" @click="gobackBtn">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png"
            class="image_7" />
          <span class="text_4">返回订单列表</span>
        </div>
      </div>
      <div class="flex-col space-y-9 section_3">
        <div class="flex-col section_4">
          <div class="section_5">
            <!---->
          </div>
          <div class="flex-row justify-between group_7">
            <div class="flex-row">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514/b061eb1f62d88f90cc98cfc6a601a7c6.png"
                class="image_8" />
              <span class="text_5">已报名，请尽快缴费以完成该培训课程的报名！</span>
            </div>
            <div class="flex-row group_9">
              <span class="text_6">缴费金额：</span>
              <span class="text_7">¥{{data.price}}</span>
            </div>
          </div>
          <div class="flex-row group_10 view_1">
            <span class="text_8">报名课程：</span>
            <span class="text_10">{{data.curriculum_name}}</span>
          </div>
          <div class="flex-row group_10 view_2">
            <span class="text_8">报名时间：</span>
            <span class="text_12">{{data.create_time}}</span>
          </div>
          <div class="flex-row group_11">
            <span class="text_13">订单编号：</span>
            <span class="text_14">{{data.ordersn}}</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <div class="flex-row section_7">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994935472151382.png"
              class="image_9" />
            <span class="text_15">请选择缴费方式</span>
            <span class="text_17">缴费截止日期：{{data.end_time}}</span>
          </div>
          <div class="flex-row space-x-9 group_12 view_5">
            <div class="flex-row justify-evenly section_8 " :class="[pay_type==1?'active':'']" @click="payTypeBtn(1)">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994934568014437.png"
                class="image_11" />
              <span class="text_18 text_19">微信支付</span>
            </div>
            <!-- <div class="flex-row justify-evenly section_8" :class="[pay_type==2?'active':'']" @click="payTypeBtn(2)">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994934700081604.png"
                class="image_11"
              />
              <span class="text_20">支付宝支付</span>
            </div> -->
          </div>
        </div>
        <div class="flex-col section_6">
          <div class="flex-row section_7">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994934025004085.png"
              class="image_9" />
            <span class="text_15">请选择开票信息</span>
          </div>
          <div class="flex-col space-y-20 group_12 view_8">
            <div class="flex-row  space-x-14 group_13">
              <span class="text_22">开票类型：</span>
              <div class="flex-col items-center section_10 active">
                <span class="text_18 text_23">电子发票</span>
              </div>
            </div>
            <div class="flex-row   group_14">
              <span class="text_25">发票类型：</span>
              <div class="flex-row">
                <div class="flex-col items-center section_11" :class="[invoice.invoice_id2==1?'active':'']">
                  <span class="text_18 text_26" :data-invoice_id2="1"
                    @click="selectFapiaoTypeBtn($event)">普通发票（个人）</span>
                </div>
                <div class="flex-col items-center section_11" :class="[invoice.invoice_id2==2?'active':'']">
                  <span class="text_18 text_26" :data-invoice_id2="2"
                    @click="selectFapiaoTypeBtn($event)">普通发票（单位）</span>
                </div>
                <div class="flex-col items-center section_11" :class="[invoice.invoice_id2==3?'active':'']">
                  <span class="text_18 text_26" :data-invoice_id2="3"
                    @click="selectFapiaoTypeBtn($event)">专用发票（单位）</span>
                </div>
              </div>
            </div>
            <div class="flex-col group_4">
              <div class="flex-row space-x-12 group_19">
                <span class="text_35">发票信息：</span>
                <div class="flex-row justify-between section_14" @click="changeInvoiceBtn()">
                  <span class="text_37" v-if="invoice.company !='' || invoice.name !=''">{{invoice.company}}{{invoice.name}}</span>
                  <span class="text_37" v-else>暂无发票</span>
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994938324864399.png"
                    class="image_9" />
                </div>
                <div class="flex-col items-center text-wrapper_4" @click="toAddUserInvoiceBtn($event)"><span
                    class="text_38">新增发票</span></div>
              </div>
              <div class="invoice-info">
                <div class="invoice-info-item">
                  <div class="invoice-info-item-label">发票抬头：</div>
                  <div class="invoice-info-item-value" v-if="invoice.name !='' || invoice.company !=''">{{invoice.name}}{{invoice.company}}</div>
                  <div class="invoice-info-item-value" v-else>暂无</div>
                </div>
                <div class="invoice-info-item" v-if="invoice.taxpayer_number">
                  <div class="invoice-info-item-label">纳税人识别号：</div>
                  <div class="invoice-info-item-value">{{invoice.taxpayer_number}}</div>
                </div>
                <div class="invoice-info-item" v-if="invoice.email">
                  <div class="invoice-info-item-label">接收邮箱：</div>
                  <div class="invoice-info-item-value">{{invoice.email}}</div>
                </div>
                <div class="invoice-info-item" v-if="invoice.company_address">
                  <div class="invoice-info-item-label">地址：</div>
                  <div class="invoice-info-item-value">{{invoice.company_address}}</div>
                </div>

                <div class="invoice-info-item" v-if="invoice.company_tel">
                  <div class="invoice-info-item-label">电话：</div>
                  <div class="invoice-info-item-value">{{invoice.company_tel}}</div>
                </div>
                <div class="invoice-info-item" v-if="invoice.bank">
                  <div class="invoice-info-item-label">开户行：</div>
                  <div class="invoice-info-item-value">{{invoice.bank}}</div>
                </div>
                <div class="invoice-info-item" v-if="invoice.bank_account">
                  <div class="invoice-info-item-label">银行账号：</div>
                  <div class="invoice-info-item-value">{{invoice.bank_account}}</div>
                </div>
                <div class="invoice-info-item" >
                  <div class="invoice-info-item-label">备注：</div>
                  <div class="invoice-info-item-value">{{invoice.remarks == ''?'-':invoice.remarks}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
				
				<div class="flex-col section_6">
				  <div class="flex-row section_7">
				    <img
				      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994934025004085.png"
				      class="image_9" />
				    <span class="text_15">请输入招生码（选填）</span>
				  </div>
				  <div class="flex-col space-y-20 group_12 view_8">
				    <div class="flex-row  space-x-14 group_13">
				      <span class="text_22">招生码（选填）：</span>
				      <div class="flex-row items-center">
				        <input class="zh-input" type="text" name="recruitcode" placeholder="请输入招生码" v-model="recruitcode" @blur="inputRecruitCodeBtn"/>
						<div class="recruit-txt">{{recruitTxt}}</div>
				      </div>
				    </div>
				  </div>
				</div>
				<div class="flex-col section_6" v-if="data.train_object_id == 1 && data.curriculum_cate_id == 1">
				  <div class="flex-row section_7">
				    <img
				      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994934025004085.png"
				      class="image_9" />
				    <span class="text_15">上传必要材料</span>
				  </div>
				  <div class="flex-col space-y-20 group_12 view_8">
				    <div class="flex-row  space-x-14 group_13">
				      <span class="text_22">报名表：</span>
				      <div class="flex-col items-center">
				        <el-upload :action="uploadImg" :on-success="handleApplicationFormSuccess" :before-upload="beforeUpload" limit="1" list-type="picture-card" :show-file-list="false">
				          <img width="100%" v-if="application_form" :src="application_form">
				          <i class="el-icon-plus" v-else></i>
				        </el-upload>
				        <div class="reg-user-info-master"> *请上传<!-- 海南省住房和城乡建设领域施工现场专业人员职业培训 -->报名表，仅支持上传JPG、PNG格式，大小为500KB内</div>
				      </div>
				    </div>
					<div class="flex-row  space-x-14 group_13">
					  <span class="text_22">工作年限证明：</span>
					  <div class="flex-col items-center">
					    <el-upload :action="uploadImg" :on-success="handleWorkingCertificateSuccess" :before-upload="beforeUpload" limit="1"  list-type="picture-card" :show-file-list="false">
					      <img width="100%" v-if="working_certificate" :src="working_certificate">
					      <i class="el-icon-plus" v-else></i>
					    </el-upload>
					    <div class="reg-user-info-master"> *请上传施工现场专业岗位工作年限证明，仅支持上传JPG、PNG格式，大小为500KB内</div>
					  </div>
					</div>
				  </div>
				</div>
				
        <div class="flex-row justify-between section_13">
          <div class="flex-row group_15">
            <span class="text_31">您需要支付：</span>
            <span class="text_32">¥{{data.price}}</span>
          </div>
          <div class="flex-row space-x-20 group_16">
            <span class="text_33" @click="cencalBaomingBtn()">取消报名</span>
            <div class="flex-col items-center text-wrapper_3" @click="showCodePayBtn()"><span
                class="text_34">立即缴费</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col items-center section_16" v-if="selectInvoice">
      <div class="flex-col space-y-26 section_17">
        <div class="flex-col group_21">
          <span class="text_45">选择开票信息</span>
          <el-table :data="userInvoiceList" style="width: 100%" @row-click="singleElection" highlight-current-row>
            <el-table-column align="center" width="55" label="选择">
              <template slot-scope="scope">
                <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
                <el-radio class="radio" v-model="templateSelection" :label="scope.row.id">&nbsp;</el-radio>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="invoice_id1_name" label="发票类型" width="80"> </el-table-column>
            <el-table-column align="center" prop="invoice_id2_name" label="开票信息类型" width="150"> </el-table-column>
            <el-table-column align="center" prop="name,company" label="姓名" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.name !=''">{{scope.row.name}}</span>
                <span v-if="scope.row.company != ''">{{scope.row.company}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="email" label="邮箱" width="250"></el-table-column>
          </el-table>
        </div>
        <el-pagination background layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange"
          :total="count">
        </el-pagination>
        <div class="flex-row space-x-10 group_24">
          <div class="flex-col items-center text-wrapper_5" @click="hidenBtn()"><span class="font_2 text_61">返回</span>
          </div>
          <div class="flex-col items-center text-wrapper_6" v-if="userInvoiceList.length != 0" @click="sureBtn()"><span
              class="font_11">确认选择</span></div>
        </div>
      </div>
    </div>
    <!-- 立即支付 -->
    <div class="pay-show" v-if="showCodePay">
      <div class="pay-show-cont">
        <div class="pay-hidee" @click="hiddenCodePayBtn()">×</div>
        <div class="pay-show-title">扫码支付</div>
        <div class="pay-show-nub">{{price}}</div>
        <vue-qr :text="codeUrl" :size="200"></vue-qr>
        <div class="pay-show-but" @click="payOver()">若支付已完成，请点击</div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueQr from 'vue-qr';
  import interfaces from '../../../../utils/interface.js'
  export default {
    components: {
      VueQr
    },
    data() {
      return {
        ordersn: '', //订单号
        data: [], //订单详情
        pay_type: 1, //支付类型
        invoice_id2: 1, //发票类型
        userInvoiceList: [], //用户开票信息列表
        userInvoice: [], //重组后的用户开票信息
        invoiceKey: '', //选中的发票key
        userInvoiceId: 0, //用户选中的发票id
		uploadImg: interfaces.uploadImages, //上传图片接口地址
		application_form:'',//报名表
		working_certificate:'',//工作证明
        invoice: {
          invoice_id2: 1,
          company: '',
          name:'',
          email: '',
          bank_account: '',
          company_address: '',
          taxpayer_number: '',
          company_tel: '',
          remarks: '',
          bank: ''
        }, //选中的发票信息
        userInvoiceListPersonal: [], //发票为个人1
        userInvoiceListEnterprise: [], //发票为企业2
        userInvoiceListSpecial: [], //发票为专用3
        selectInvoice: false,
        showCodePay: false, // 弹出支付码
        codeUrl: '', // 微信支付二维码
        price: '', //支付金额
        pay_status: '', //支付状态,
        success: '', //支付情况
        timer: '', //定时器
        templateSelection: '', //   当前选择的行的id
        checkList: [], //   当前选择的行的数据
        count: 0,
        page: 1,
        limit: 10,
        totalpage: 0,
		recruitTxt:'',
		recruitcode:'',
      };
    },
    created() {
      let that = this;
      //获取订单详情
      that.getOrderDetail();
      //我的所有发票
      that.getUserInvoiceList();
      // 获取微信支付地址
      // that.getScannedCodePay();
      //支付成功刷新
      // that.getPayIssuccess();

    },
    methods: {
      // 选中发票信息
      singleElection(row) {
        let that = this;
        that.templateSelection = row.id;
        that.checkList = that.userInvoiceList.filter((item) => item.id === row.id);
        console.log(that.checkList)
        console.log(row.id)
      },
      // 我的发票列表分页
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getUserInvoiceList();
      },
      //获取订单详情
      getOrderDetail() {
        let that = this;
        let ordersn = that.$route.query.ordersn;
        that.ordersn = ordersn;
        let param = {
          ordersn,
        };
        that.$request.getOrderDetail(param).then((res) => {
          if (res.code == 200) {
            that.data = res.datas;
			that.application_form = res.datas.application_form;
			that.working_certificate = res.datas.working_certificate;
            that.pay_status = res.datas.pay_status;
            that.price = res.datas.price;
          } else {
            that.$myMessage.error(res.msg);
          }
        });
      },
      //选择支付类型
      payTypeBtn(pay_type) {
        let that = this;
        that.pay_type = pay_type;
      },

      //选择发票类型
      selectFapiaoTypeBtn(e) {
        let that = this;
        if (that.userInvoiceList.length != 0) {
          if (e.target.dataset.invoice_id2 == 1) {
            that.invoice = that.userInvoiceListPersonal[0];
          } else if(e.target.dataset.invoice_id2 == 2) {
            that.invoice = that.userInvoiceListEnterprise[0];
            }
          else {
            that.invoice = that.userInvoiceListSpecial[0];
          }
        } else {
          this.$message({
            type: 'info',
            message: '请添加发票信息'
          });
        }


      },
      // 已支付
      payOver() {
        let that = this;
        that.getPayIssuccess();
        that.showCodePay = false;
        if (that.success == 1) {
          that.$router.push({
            'path': 'paymentSucceeded',
            query: {
              ordersn: that.ordersn
            }
          })
          clearInterval(that.timer)
        } else {
          that.$message.error('支付未完成，请前往订单支付');
          clearInterval(that.timer)
        }
      },
      // 刷新查看支付
      getPayIssuccess() {
        let that = this;
        let param = {
          ordersn: that.ordersn
        };
        that.$request.getPayIssuccess(param).then((res) => {
          if (res.code == 200) {
            that.success = res.datas.success;
            if (that.success == 1) {
              that.$router.push({
                'path': 'paymentSucceeded',
                query: {
                  ordersn: that.ordersn
                }
              })
              clearInterval(that.timer)
            }
          }
        });
      },
      //获取我的开票信息
      getUserInvoiceList() {
        let that = this;
        let param = {
          page: that.page,
          limit: that.limit,
        };
        that.$request.getUserInvoiceList(param).then((res) => {
          if (res.code == 200) {
            that.userInvoiceList = res.datas;
            that.page = that.page;
            that.totalpage = res.total_page;
            that.count = res.count;
            // 分类列表
            if (that.userInvoiceList.length != 0) {
              that.userInvoiceListPersonal = that.userInvoiceList.filter(item => item.invoice_id2 == 1)
              that.userInvoiceListEnterprise = that.userInvoiceList.filter(item => item.invoice_id2 == 2)
              that.userInvoiceListSpecial = that.userInvoiceList.filter(item => item.invoice_id2 == 3)
              that.invoice = that.userInvoiceListPersonal[0];
            };

          } else {
            that.$myMessage.error(res.msg);
          }
        });
      },
      //取消报名
      cencalBaomingBtn() {
        let that = this;
        that.$confirm('确定取消报名培训课程【' + that.data.curriculum_name + '】吗？', '取消报名', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let ordersn = that.ordersn;
          let param = {
            ordersn: ordersn
          };
          that.$request.cencalBaoming(param).then((res) => {
            if (res.code == 200) {
              that.$myMessage.success(res.msg);
              that.$router.push({
                name: 'userOrder'
              });
            }
          });
        }).catch(() => {
          /* this.$message({
		  	type: 'info',
		  	message: '已取消删除'
		    }); */
        });

      },
      // 确认选择
      sureBtn() {
        let that = this;
        that.invoice = that.checkList[0];
        if (that.checkList[0].name == '') {
          that.invoice.title = that.checkList[0].company
        } else {
          that.invoice.title = that.checkList[0].name
        }
        that.selectInvoice = false;
      },
      //弹出用户发票信息列表
      changeInvoiceBtn() {
        let that = this;
        if (that.userInvoiceList.length != 0) {
          that.selectInvoice = true;
          that.getUserInvoiceList();
        } else {
          this.$message({
            type: 'info',
            message: '请添加发票信息'
          });
        }
      },
      // 立即缴费
      getScannedCodePay() {
        let that = this;
		if(that.data.train_object_id == 1 && that.data.curriculum_cate_id == 1){
			/* if(!that.application_form){
				this.$message({
				  type: 'error',
				  message: '请上传施工现场专业人员职业培训报名表'
				});
				return false;
			} */
			/* if(!that.working_certificate){
				this.$message({
				  type: 'error',
				  message: '请上传施工现场专业岗位工作年限证明'
				});
				return false;
			} */
		}
        let param = {
          pay_type: 1,
          ordersn: that.ordersn,
          user_invoice_id: that.invoice.id,
          recruitcode: that.recruitcode,
		  application_form:that.application_form,
		  working_certificate:that.working_certificate,
        };
        that.$request.getScannedCodePay(param).then((res) => {
          if (res.code == 200) {
            that.codeUrl = res.datas.code_url;
          } else {
            that.$myMessage.error(res.msg);
          }
        });


      },
      // 弹出支付码
      showCodePayBtn() {
        let that = this;
        if (that.userInvoiceList.length != 0) {
          that.showCodePay = true;
        } else {
          this.$message({
            type: 'info',
            message: '请添加发票信息'
          });
        }
		if(that.data.train_object_id == 1 && that.data.curriculum_cate_id == 1){
			/* if(!that.application_form){
				this.$message({
				  type: 'error',
				  message: '请上传施工现场专业人员职业培训报名表'
				});
				return false;
			} */
			/* if(!that.working_certificate){
				this.$message({
				  type: 'error',
				  message: '请上传施工现场专业岗位工作年限证明'
				});
				return false;
			} */
		}
		
        that.getScannedCodePay();
        that.timer = setInterval(() => {
          that.getPayIssuccess()
        }, 10000)
        if (that.success == 1) {
          that.$router.push({
            name: 'paymentSucceeded',
            query: {
              ordersn: that.ordersn
            }
          })
          clearInterval(that.timer);
        }

      },
      hiddenCodePayBtn() {
        let that = this;
        that.showCodePay = false;
        clearInterval(that.timer)
      },
	  inputRecruitCodeBtn(){
		  let that = this;
		  if(that.recruitcode){
			  that.getHasRecruit();
		  }else{
			  that.recruitTxt = '';
		  }
		  
	  },
	  // 判断是否存在招生员
	  getHasRecruit(){
		let that = this;
		let param = {
		  recruitcode:that.recruitcode,
		};
		console.log(param);
		that.$request.getHasRecruit(param).then((res) => {
			if (res.code == 200) {
				that.recruitTxt = '招生老师为：'+res.datas.name;
			}else{
				that.recruitTxt = '招生老师不存在';
			}
			console.log(that.recruitTxt);
		});
	  },

      //隐藏用户发票
      hidenBtn() {
        let that = this;
        that.selectInvoice = false;
      },
      //跳转新增发票信息
      toAddUserInvoiceBtn() {
        let that = this;
        that.$router.push({
          name: 'userInvoiceAdd',
          query: {
            ordersn: that.ordersn
          }
        })
      },
	  //报名表上传成功
	  handleApplicationFormSuccess(res, file) {
	    let that = this;
	    if (res.code == 200) {
	      console.log(file.raw);
	      that.loading = false;
	      that.application_form = res.datas.location;
	    } else {
	      that.loading = false;
	      that.$myMessage.warning(res.msg);
	    }
	  },
	  handleWorkingCertificateSuccess(res, file) {
	    let that = this;
	    if (res.code == 200) {
	      console.log(file.raw);
	      that.loading = false;
	      that.working_certificate = res.datas.location;
	    } else {
	      that.loading = false;
	      that.$myMessage.warning(res.msg);
	    }
	  },
	  beforeUpload(file) {
	    let that = this;
	    that.loading = true;
	    const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
	    const isLt2M = file.size / 1024 / 1024 < 2;
	  
	    if (!isJPG) {
	  	that.loading = false;
	  	this.$message.error('上传图片只能是 jpg、jpeg、png 格式!');
	    }
	    if (!isLt2M) {
	  	that.loading = false;
	  	this.$message.error('上传图片大小不能超过 2MB!');
	    }
	    return isJPG && isLt2M;
	  },
	  
	  
      //返回
      gobackBtn() {
        let that = this;
        that.$router.go(-1)
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #f5f6fa;
    width: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }


  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }
  
  .zh-input{
	width: 100%;
	height: 30px;
	border: 1px solid #ccc;
	border-radius: 4px;
	outline: none;
  }
  
  .recruit-txt{
	color:red;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .group_3 {
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .group_4 {
    overflow: hidden;
  }

  .view {
    margin-left: 1.56rem;
    margin-right: 1.38rem;
  }

  .group_5 {
    margin: 0.19rem 0 0.13rem;
    padding: 0.19rem 0 0.13rem;
    overflow: hidden;
    width: 12.25rem;
    height: 1.25rem;
  }

  .text_2 {
    margin-bottom: 0.13rem;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .text_3 {
    margin-bottom: 0.13rem;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .group_6 {
    overflow: hidden;
    width: 7.13rem;
    height: 1.56rem;
    cursor: pointer;
  }

  .image_7 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
  }

  .text_4 {
    margin: 0.38rem 0 0.38rem 0.41rem;
    flex-shrink: 0;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-y-9>*:not(:first-child) {
    margin-top: 0.56rem;
  }

  .section_3 {
    padding: 1.25rem 1.38rem 2.19rem 1.44rem;
    background-color: #ffffff;
  }

  .section_4 {
    padding-bottom: 1.25rem;
    background-color: #fbfcff;
  }

  .section_5 {
    background-color: #0074ff;
    height: 0.63rem;
  }

  .group_7 {
    padding: 1.25rem 4.06rem;
  }

  .image_8 {
    flex-shrink: 0;
    border-radius: 50%;
    width: 1.88rem;
    height: 1.88rem;
  }

  .text_5 {
    margin: 0.25rem 0 0.25rem 1rem;
    color: #2f3541;
    font-size: 1.38rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.38rem;
  }

  .group_9 {
    margin: 0.31rem 0.81rem 0.31rem 0;
    padding-left: 0.094rem;
    overflow: hidden;
    /* width: 12.19rem; */
    height: 1.25rem;
  }

  .text_6 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 1.38rem;
    font-family: Source Sans Pro;
    line-height: 1.28rem;
  }

  .text_7 {
    margin: 0.25rem 0 0.094rem 0.44rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 1.38rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.91rem;
  }

  .group_10 {
    align-self: flex-start;
    padding: 0.19rem 0 0.25rem;
    overflow: hidden;
    /* width: 11.38rem; */
  }

  .view_1 {
    margin-left: 6.88rem;
  }

  .text_8 {
    flex-shrink: 0;
    color: #9a9da7;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_10 {
    flex-shrink: 0;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .view_2 {
    margin-left: 6.88rem;
    margin-top: 0.63rem;
  }

  .text_12 {
    margin-top: 0.16rem;
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.59rem;
  }

  .group_11 {
    margin-left: 6.88rem;
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.25rem;
    align-self: flex-start;
    overflow: hidden;
    width: 14.94rem;
  }

  .text_13 {
    flex-shrink: 0;
    color: #9a9da7;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_14 {
    margin-top: 0.16rem;
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.59rem;
  }

  .section_6 {
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .section_7 {
    padding: 0.84rem 1.16rem;
    background-color: #fbfcff;
    border: solid 0.063rem #dcdfe6;
  }

  .image_9 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .text_15 {
    margin: 0.16rem 0 0.16rem 0.59rem;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_17 {
    margin: 0.19rem 0 0.25rem 0.88rem;
    color: #ff5f5a;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-9>*:not(:first-child) {
    margin-left: 0.56rem;
  }

  .group_12 {
    margin-left: 1.16rem;
    align-self: flex-start;
  }

  .view_5 {
    padding: 1.25rem 0 1.34rem;
  }

  .section_8 {
    padding: 0.53rem 1.34rem 0.59rem;
    flex: 1 1 9.06rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    height: 3.13rem;
    position: relative;
    border: solid 0.063rem #dcdfe6;
    color: #3d3d3d;
    cursor: pointer;
  }

  .active {
    border: solid 0.063rem #0074ff !important;
    color: #0074ff !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAMAAAA+9+1qAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADhUExURQAAABSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/xSS/////yGY/02t/4HE/3/E/8rn/9zv/8rm/4/L/3zC/yaa/0mr//7//1Gu/8Lj/1my/268/3W//+73/+Xz/9nt//f7/6bW/x+X/93v/2e4/6jX/0Kn/1Ov/0ap/zmj/9Lq/yqc/5XO/63Z//n8/7rf/y+e//P5/+Ly/5HM/zOh//T6/8Di/+r1/8Pj/x2W/9Hq/45fPE0AAAAadFJOUwAyIOy13n8CCP33YsxImxITnEnj2OQRkRCSfiet/QAAANlJREFUOMuF0ddawkAUhdFAKKGKdM9GQVB6R0EBpdf3fyDiR4RhJuTsi1yti/xnNI2dEeWNO0ysiejEIb8vQBx68BBxyOUlFoWCxCEjSsQhs5xFf+UMOpc7I6vcEf2XO6FL+X0klFO1+GKLxPLhAK92SCxfj4GSiqzy5/y7+Z3vgc++gmJWeaHSO1DuB9hOlB+/lNeAaW4FHL+Vumt5uY3f2QbLL/kExqNwl3oLwG4k30ksN9foYvEhHzOuSzd+6zSli0tvriwllN9dxubN5SXTNm9+u8RTVjsBbU82g3zDdeMAAAAASUVORK5CYII=) no-repeat 100% 100%;
  }

  .image_11 {
    width: 1.88rem;
    height: 1.88rem;
  }

  .image_13 {
    width: 2rem;
    height: 1.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .text_18 {
    position: relative;
  }

  .text_19 {
    margin: 0.47rem 0;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .section_9 {
    padding: 0.53rem 0.84rem 0.59rem;
    flex: 1 1 9.06rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
    cursor: pointer;
  }

  .text_20 {
    margin: 0.47rem 0;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .view_8 {
    padding: 1.25rem 0 1.34rem;
    width: 38rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .group_13 {
    align-self: flex-start;
    overflow: hidden;
    width: 100.13rem;
  }

  .text_22 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .section_10 {
    padding: 1rem 0 1.06rem;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 7.13rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
    position: relative;
  }

  .image_17 {
    flex-shrink: 0;
  }

  .text_23 {
    /* color: #0074ff; */
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
    cursor: pointer;
  }

  .text-wrapper {
    padding: 1rem 0 1.06rem;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 7.13rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
  }

  .text_24 {
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_14 {
    align-self: flex-start;
    overflow: hidden;
    /* width: 26.5rem; */
  }

  .text_25 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .section_11 {
    padding: 1rem 0 0.97rem;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 9.81rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
    position: relative;
    margin-left: 0.88rem;
  }

  .text_26 {
    /* color: #0074ff; */
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
    cursor: pointer;
  }

  .text-wrapper_1 {
    padding: 1rem 0 0.97rem;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 9.81rem;
    height: 3.13rem;
    border: solid 0.063rem #dcdfe6;
  }

  .text_27 {
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 1.03rem;
  }

  .text_28 {
    margin-top: 1.09rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .space-y-14>*:not(:first-child) {
    margin-top: 0.88rem;
  }

  .section_12 {
    padding: 0.72rem 0.81rem 0.97rem;
    flex: 1 0 auto;
    background-color: #f5f8ff;
    width: 28.13rem;
    height: 5.63rem;
    border: dotted 0.031rem #0074ff;
  }

  .text_29 {
    color: #999999;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_2 {
    padding: 0.69rem 0;
    background-color: #67c23a;
    border-radius: 0.31rem;
    width: 6.25rem;
    cursor: pointer;
  }

  .text_30 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .section_13 {
    padding: 1.25rem 1.56rem;
    background-color: #fbfcff;
  }

  .section_14 {
    padding: 0.84rem 1.44rem 0.91rem;
    flex: 1 1 auto;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 18.69rem;
    height: 3.13rem;
    border: solid 0.063rem #0074ff;
    cursor: pointer;
  }

  /*
  .section_15 {
    padding: 0.84rem 0.84rem 0.97rem;
    background-color: #f5f8ff;
    width: 28.13rem;
    border: dotted 0.031rem #0074ff;
  }
 */
  .section_16 {
    padding: 10.69rem 0 14.5rem;
    background-color: #0000004d;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  .section_17 {
    padding: 0 1.56rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
  }

  .section_18 {
    margin-top: 1.38rem;
    padding: 0.81rem 1.75rem;
    background-color: #ebeef5;
  }

  .section_19 {
    flex-shrink: 0;
    align-self: center;
    background-color: #ffffff;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    border: solid 0.13rem #0074ff;
  }

  .section_20 {
    flex-shrink: 0;
    align-self: center;
    background-color: #ffffff;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    border: solid 0.13rem #c0c4cc;
  }

  .space-y-26>*:not(:first-child) {
    margin-top: 1.63rem;
  }

  /*  .space-x-13>*:not(:first-child) {
    margin-left: 0.81rem;
  } */
.reg-user-info-master {
    color: #FF5F5A;
    font-size: 0.75rem;
  }
  .group_15 {
    padding: 0.19rem 0 0.25rem;
    align-self: center;
    overflow: hidden;
    /* width: 8.94rem; */
    height: 1.25rem;
  }

  .group_19 {
    margin-right: 1.69rem;
    padding-bottom: 0.59rem;
  }

  /*  .group_20 {
    padding: 0.19rem 0 0.25rem;
    overflow: hidden;
    /* width: 7.94rem; */

  .group_21 {
    padding-top: 1.63rem;
    border-bottom: solid 0.063rem #e9e9e9;
  }

  .group_22 {
    flex-shrink: 0;
  }

  .group_23 {
    flex-shrink: 0;
  }

  .group_24 {
    padding: 1.56rem 0;
  }

  .group_25 {
    padding: 0.69rem 1.63rem 0.88rem 2.19rem;
    border-bottom: solid 0.063rem #e9e9e9;
  }

  .group_26 {
    padding: 0.94rem 1.63rem 0.88rem 2.19rem;
  }

  .text_31 {
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_32 {
    margin-top: 0.16rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 1rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.66rem;
  }

  .space-x-20>*:not(:first-child) {
    margin-left: 1.25rem;
  }

  .group_16 {
    margin-right: 0.31rem;
  }

  .text_33 {
    align-self: center;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    cursor: pointer;
  }

  .text-wrapper_3 {
    cursor: pointer;
    padding: 0.94rem 0 1rem;
    flex-shrink: 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 6rem;
    height: 2.75rem;
  }

  .text-wrapper_4 {
    padding: 1.09rem 0;
    flex-shrink: 0;
    background-color: #67c23a;
    border-radius: 0.63rem;
    width: 7.13rem;
    height: 3.13rem;
    cursor: pointer;
  }

  .text-wrapper_5 {
    margin-left: 16.25rem;
    padding: 0.75rem 0 0.69rem;
    background-color: #f5f8ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text-wrapper_6 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_34 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_35 {
    align-self: center;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_37 {
    margin: 0.16rem 0;
    color: #0074ff;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_38 {
    color: #ffffff;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .invoice-info {
    padding: 1rem 0.5rem;
    width: 30rem;
    background-color: #F5F8FF;
    border: dotted #0074FF 1px;
  }

  .invoice-info-item {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 0.85rem;
    line-height: 2;
  }

  .invoice-info-item-label {
    margin-right: 0.8rem;
    width: 8rem;
    color: #999;
    text-align: right;
  }

  .invoice-info-item-value {
    width: 20rem;
    color: #303133;
  }

  .font_11 {
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    color: #ffffff;
  }

  .text_45 {
    align-self: flex-start;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    line-height: 1.06rem;
  }

  .text_46 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_47 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_48 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_49 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_50 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_51 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_52 {
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_53 {
    margin-right: 0.31rem;
    font-size: 0.81rem;
    line-height: 0.75rem;
  }

  .text_54 {
    margin-left: 0.94rem;
    flex-shrink: 0;
    width: 3.75rem;
  }

  .text_55 {
    flex-shrink: 0;
    align-self: center;
    width: 6rem;
  }

  .text_56 {
    margin-left: 1.25rem;
    margin-top: 2.75rem;
    flex-shrink: 0;
    text-align: center;
    word-break: break-all;
    width: 3.63rem;
  }

  .text_57 {
    margin-left: 1.88rem;
    margin-top: 2.75rem;
    flex-shrink: 0;
    text-align: center;
    width: 3.31rem;
  }

  .text_58 {
    flex-shrink: 0;
    align-self: center;
  }

  .text_59 {
    margin-left: 1.5rem;
    flex-shrink: 0;
    align-self: center;
  }

  .text_60 {
    margin-left: 1.38rem;
    flex-shrink: 0;
    align-self: center;
  }

  .text_61 {
    line-height: 0.78rem;
  }

  .text_62 {
    flex-shrink: 0;
    align-self: center;
  }

  .text_63 {
    margin-left: 1.5rem;
    flex-shrink: 0;
    align-self: center;
  }

  .text_64 {
    margin-left: 1.38rem;
    flex-shrink: 0;
    align-self: center;
  }

  .space-x-32>*:not(:first-child) {
    margin-left: 2rem;
  }

  .font_5 {
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    color: #000000;
  }

  .font_6 {
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
    color: #333333;
  }

  .font_7 {
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    color: #333333;
  }

  .font_8 {
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.72rem;
    color: #333333;
  }

  .font_9 {
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    color: #333333;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .font_2 {
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    color: #0074ff;
  }

  .space-x-44>*:not(:first-child) {
    margin-left: 2.75rem;
  }

  .pay-show {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  .pay-show-title {
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 2rem;
    color: #3D3D3D;
  }

  .pay-show-cont {
    position: relative;
    margin: 5rem 30% 0;
    padding-bottom: 5rem;
    width: 20%;
    background-color: #fff;
    border: 1px solid #DCDFE6;
    border-radius: 1rem;
  }

  .pay-show-nub {
    color: #FF5F5A;
  }

  .pay-hidee {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    font-size: 2rem;
    color: #606266;
  }

  .pay-show-but {
    cursor: pointer;
    margin: auto;
    width: 50%;
    background-color: #0074ff;
    color: #fff;
    border-radius: 5px;
    line-height: 3;
  }
</style>
